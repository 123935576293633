import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Game from './components/game'
import Admin from './components/admin'

function App() {
    console.log('start react')

    // routes
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Game />} />
                <Route path="dashboard" element={<Admin />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
