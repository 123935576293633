import React, { useEffect, useRef } from "react";
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import Cookies from 'universal-cookie';
import axios from "axios";

import '../css/game.css';

const TOTAL_CHECKBOXES = 1000000;
const CHECKBOX_SIZE = 36; //px
const gridRef = React.createRef();
var colCount, rowCount;

var delay = false;

var socket;
var cookies;
var tele;
var tele_user_id;

//Main Page
function Game() {

    //websockets
    useEffect(() => {

        socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_SERVER);

        socket.onopen = function() {
            console.log('[WS connected]');
            // keepAlive();
            getRange();
        };

        socket.onmessage = function(event) {
            console.log(`[WS message] ${event.data}`);

            const parts = event.data.split(":");
            const action = parts[0];

            switch (action) {
                case 'Set':
                    var id = parts[1];
                    var status = parts[2] === '1' ? true : false;
                    setCheckboxStatus(id, status);
                    break;

                case 'SetRange':
                    var start = parts[1];
                    var end = parts[2];
                    var ids = parts[3].split(",");
                    //clear the whole range
                    for (start; start <= end; start++) {
                        setCheckboxStatus(start, false);
                    }
                    //set checked items
                    ids.forEach(function(id, key){
                        setCheckboxStatus(id, true);
                    });
                    break;

                case 'Total':
                    var total = parts[1];
                    updateTotalCounter(total);
                    break;

                case 'Reset':
                    resetCheckboxes();
                    break;

                default:
                    console.log(`[WS unknown action]`)
                    break;
            }
        };


        socket.onclose = function(event) {
            console.log('[WS disconnected]');
        };

        socket.onerror = function(error) {
            console.log(`[WS error] ${error.message}`);
        };

    }, []);


    useEffect(() => {
        cookies = new Cookies();

        //telegram webapp
        tele = window.Telegram.WebApp;
        tele.ready()
        tele.expand()
        updateTeleUser();

        window.addEventListener('resize', function(event){
            console.log('resize');
            getRange();
        });


        window.onfocus = function() {
            console.log('Focus with socket.readyState: ' + socket.readyState);
            if (socket) {
                if (socket.readyState === socket.CLOSED || socket.readyState === socket.CLOSING) {
                    window.location.reload();
                }
            }
        }


    }, []);

    return (
        <div className="wrapper">
            <Header />
            <CheckBoxesTable />
        </div>
    );
}


function Header() {
    function handleClick() {
        alert(window.Telegram.WebApp.initDataUnsafe.user.user_id + ' ' + window.Telegram.WebApp.initDataUnsafe.user.username + ' ' +  cookies.get('local_counter'))
    }
    return (
        <div className="header">
            <JumpBox />
            <div className="stats_wrapper">
                <div className="stats_left">
                    <div><span id="total_count">0</span> boxes are <span role="img" aria-label="checkbox">✅</span></div>
                    <div className="mtop" onClick={handleClick} >You checked <span id="local_count">0</span> boxes</div>
                </div>
                <div className="stats_right">
                    <a href="https://t.me/millioncheckboxes">Community</a>
                    <div className="coin mtop"><span id="balance">340</span></div>
                </div>
            </div>
        </div>
    );
}


function CheckBoxesTable() {

    function handleScroll(e) {
        console.log('scroll');
        getRange();
    }

    return (
        <div className="table" onScroll={handleScroll}>
             <AutoSizer>
                {({ height, width}) => (
                    <GridWrapper height={height} width={width} />
                )}
            </AutoSizer>
        </div>
    );
}


function GridWrapper({ height, width }) {
    colCount = Math.floor(width / CHECKBOX_SIZE - 1);
    rowCount = Math.ceil(TOTAL_CHECKBOXES / colCount);
    return (
        <Grid className="grid"
            columnCount={colCount}
            columnWidth={CHECKBOX_SIZE}
            height={height}
            rowCount={rowCount}
            rowHeight={CHECKBOX_SIZE}
            width={width}
            ref={gridRef}
        >
            {Cell}
        </Grid>
    );
}

function Cell({ columnIndex, rowIndex, style }) {
    var id = colCount * rowIndex + columnIndex + 1; //start IDs from 1
    if (id > TOTAL_CHECKBOXES) {
        return (
            <div className="empty_wrapper" style={style}></div>
        );
    } else {
        return (
            <div className="checkbox_wrapper" data-id={id} key="{rowIndex}:{columnIndex}" style={style}>
                <Checkbox id={id} />
                <div className="checkbox_border safari_only" data-id={id}></div>
            </div>
        );
    }
}

function Checkbox({id}) {

    function handleClick(e) {
        //send new status
        const id = e.target.id;
        const status = e.target.checked ? 1 : 0;

        var message = `Set:${id}:${status}`
        if (tele_user_id) {
            message = message + `:${tele_user_id}`
        }
        socket.send(message);

        //update border
        updateBorder(id);

        //update local counter
        updateLocalCounter(status);
    }

    return (
        <input className="checkbox" type="checkbox" onClick={(e) => handleClick(e)} id={id} />
    );
}

function JumpBox() {
    const jump_number = useRef();

    function jump()  {
        const id = jump_number.current.value;
        const row = Math.floor(id / colCount);
        console.log('jump to row ' + row);

        jump_number.current.blur();

        setTimeout(function() {
            gridRef.current.scrollToItem({
                align: "center",
                rowIndex: row,
            });
            updateBorder(id);
        }, 500);


    }

    return (
        <div className="jump_wrapper">
            <input type="tel" ref={jump_number} className="jump_number" placeholder="box number" min="1" max="1000000" maxLength="7" defaultValue="" />
            <button className="jump_button" onClick={jump}>
                Jump!
            </button>
        </div>
    );
}

function resetCheckboxes() {
    getRange();
    alert("Congratulations!\nAll the checkboxes are checked.\nLet's try again");
}

function updateTotalCounter(total) {
    const counter = document.getElementById('total_count');
    if (counter) {
        counter.innerText = total;
    }
}

function updateTeleUser() {
    //get telegram user data
    if (window.Telegram.WebApp.initDataUnsafe.user) {
        tele_user_id = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
        var tele_username = window.Telegram.WebApp.initDataUnsafe.user.username;
        var tele_name = window.Telegram.WebApp.initDataUnsafe.user.first_name + ' ' + window.Telegram.WebApp.initDataUnsafe.user.last_name;

        //create user and/or get user counter
        axios.post(process.env.REACT_APP_API_SERVER + "/user", {
            'user_id': tele_user_id,
            'username': tele_username,
            'name': tele_name
        }).then((response) => {
            //alert(JSON.stringify(response));
            if (response.data.success === true) {
                var checked = response.data.checked;
                cookies.set('local_counter', checked, { path: '/', maxAge: 604800 });
                updateLocalCounter();
            }
        });
    } else {
        updateLocalCounter();
    }
}

function updateLocalCounter(status = null) {
    var local_counter = cookies.get('local_counter');
    if (!local_counter) {
        local_counter = 0
    }

    if (status !== null) {
        if (status) {
            local_counter++;
        } else {
            local_counter--;
        }
        cookies.set('local_counter', local_counter, { path: '/', maxAge: 604800 });
    }

    const counter = document.getElementById('local_count');
    if (counter) {
        counter.innerText = local_counter;
    }

    var money = local_counter / 100;
    money = Math.max(0, money);
    money = money.toFixed(2);
    const balance = document.getElementById('balance');
    if (balance) {
        balance.innerText = `Earned: ${money}`;
    }

}

function getRange() {
    if (delay) return;

    delay = true;
    setTimeout(function(){
        delay = false
        var first = document.querySelector('.checkbox_wrapper:first-of-type');
        var last = document.querySelector('.checkbox_wrapper:last-of-type') ?? document.querySelector('.checkbox_wrapper[data-id="'+TOTAL_CHECKBOXES+'"]');
        if (first && last) {
            var first_id = first.getAttribute('data-id');
            var last_id = last.getAttribute('data-id');
            var command = `GetRange:${first_id}:${last_id}`;
            console.log(command);
            socket.send(command);
        }

    }, 500);
}

function setCheckboxStatus(id, status) {
    const checkbox = document.getElementById(id);
    if (checkbox) {
        checkbox.checked = status;
    }
}

function updateBorder(id) {
    const old_checkbox = document.querySelector('.checkbox_border.active');
    if (old_checkbox) {
        old_checkbox.classList.remove('active');
    }

    const new_checkbox = document.querySelector('.checkbox_border[data-id="'+id+'"]')
    if (new_checkbox) {
        new_checkbox.classList.add('active');
    }
}


export default Game;