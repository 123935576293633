import React, { useState, useMemo } from "react";
import { useTable } from "react-table";
import axios from "axios";
//import ReactDOM from "react-dom";
import "../css/admin.css";

function Admin() {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [data, setData] = useState([]);

	async function getPlayers(api_key) {
		const headers = {
	  		'Content-Type': 'application/json',
	  		'X-API-KEY': api_key
		}
		axios.post(process.env.REACT_APP_API_SERVER + "/players",
			{},
		    {
	    		headers: headers
	  		}).then((response) => {
		     	console.log(response);
		     	setData(response.data.players);
		    });
	}

	async function handleSubmit (event) {
		//Prevent page reload
		event.preventDefault();

		var { uname, pass } = document.forms[0];
		var username = uname.value
		var password = pass.value

		axios.post(process.env.REACT_APP_API_SERVER + "/login", {
	     	username,
	     	password,
	    }).then((response) => {
	     	console.log(response);
	     	if (response.data.success === true) {
	     		setIsSubmitted(true);
	     		getPlayers(response.data.api_key);
	     	}
	    });
	}

	function LoginForm() {
		return (
			<div className="app">
		 		<div className="login-form">
		    		<div className="title">Sign In</div>
					<div className="form">
					  	<form onSubmit={handleSubmit}>
					    	<div className="input-container">
						      	<label>Username </label>
						      	<input type="text" name="uname" required />
					    	</div>
					    	<div className="input-container">
						      	<label>Password </label>
						      	<input type="password" name="pass" required />
					    	</div>
					    	<div className="button-container">
					      		<input type="submit" />
					    	</div>
					  	</form>
					</div>
				</div>
			</div>
		);
	}

	function Dashboard() {
		return (
			<div>
				<h1>
					<div className="dash_header">Admin Dashboard</div>
					<Logout />
				</h1>
				<div className="players">
					<Table data={data} />
				</div>
			</div>

		);
	}

	function Logout() {
		function handleClick(e) {
			window.location.reload();
		}
		return (
			<div className="logout" onClick={handleClick}></div>
		);
	}

	function Table({ data }) {
	  	const columns = useMemo(() => [
		      	// { Header: "ID", accessor: "id" },
		      	{ Header: "User", accessor: "username" },
		      	{ Header: "Name", accessor: "name" },
		      	{ Header: "Checked", accessor: "checked" },
		      	{ Header: "Earned", accessor: "earned" },
	    	],
	    	[]
	 	);

	 	const {
	    	getTableProps,
	    	getTableBodyProps,
	    	headerGroups,
	    	rows,
	    	prepareRow
	  	} = useTable({ columns, data });

	  	return (
		    <div className="table-container">
		    	<h2>Players Statistics</h2>
		     	<table {...getTableProps()}>
			        <thead>
			          {headerGroups.map((headerGroup) => (
			            <tr {...headerGroup.getHeaderGroupProps()}>
			              {headerGroup.headers.map((column) => (
			                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
			              ))}
			            </tr>
			          ))}
			        </thead>
			        <tbody {...getTableBodyProps()}>
			          {rows.map((row) => {
			            prepareRow(row);
			            return (
			              <tr {...row.getRowProps()}>
			                {row.cells.map((cell) => (
			                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
			                ))}
			              </tr>
			            );
			          })}
			        </tbody>
		      	</table>
		    </div>
	  	);
	}

	if (isSubmitted) {
		return ( <Dashboard /> );
	} else {
		return ( <LoginForm /> );
	}
}


export default Admin;